<template>
  <div>
    <multiple-select
      :items="vendibleTags"
      prepend-inner-icon=""
      :append-outer-icon="icons.mdiFilterVariant"
      :with-query="false"
      placeholder="商品タグ"
      class="px-3 mb-2 vendible-tag-select"
      @selected="selectedVendibleTagIds = $event"
      @cleared="selectedVendibleTagIds = []"
    />

    <app-card-actions
      v-for="(text, type) in { Course: currentClub.courseAlias, Nomination: currentClub.nominationAlias, Item: 'アイテム' }"
      :key="type"
      action-collapse
      :collapsed="{ Course: courseCollapsed, Nomination: nominationCollapsed, Item: itemCollapsed }[type]"
      outlined
      class="mb-4 mx-1"
      :class="{ [`order-menu-list-${type}-container`]: true }"
    >
      <template #title>
        {{ text }}
      </template>

      <v-data-table
        v-sortable-data-table="sortable"
        :headers="headers"
        :items="vendiblesByType[type]"
        item-key="attributes.itemKey"
        hide-default-header
        hide-default-footer
        disable-pagination
        no-data-text="データがありません"
        :class="{
          'cursor-pointer': !noCursorPointer,
          [`order-menu-list-${type}`]: true,
        }"
        @sorted="updatePositions($event, type)"
        @click:row="$emit('click:row', $event)"
      >
        <template #[`item.attributes.name`]="{ item }">
          <v-avatar
            v-if="item.attributes.color"
            size="16"
            :color="item.attributes.color"
            left
            class="mr-2"
          />
          {{ item.attributes.name }}
        </template>

        <template #[`item.attributes.sellingPrice`]="{ item }">
          {{ item.attributes.sellingPrice ? `¥${item.attributes.sellingPrice.toLocaleString()}` : '¥0' }}
        </template>

        <template #[`item.attributes.tags.data`]="{ item }">
          <v-chip-group
            v-if="item.attributes.tags.data && item.attributes.tags.data.length > 0"
            column
            small-chips
          >
            <v-chip
              v-for="tag in item.attributes.tags.data"
              :key="`vendible${item.id}-tag${tag.id}`"
              x-small
              class="my-2"
            >
              {{ tag.attributes.name }}
            </v-chip>
          </v-chip-group>
        </template>

        <template #[`item.actions`]="{ item }">
          <slot
            name="action"
            :item="item"
          />

          <v-icon
            v-if="!hasActionSlot"
            small
            color="primary"
          >
            {{ icons.mdiPlus }}
          </v-icon>
        </template>
      </v-data-table>
    </app-card-actions>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { chain, map, intersectionBy } from 'lodash'
import { mdiPlus, mdiFilterVariant } from '@mdi/js'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import sortableDataTable from '@/directives/sortableDataTable'
import MultipleSelect from '@/views/components/util/filter/MultipleSelect.vue'
import ItemApi from '@/api/waiter/Item'
import NominationApi from '@/api/waiter/Nomination'
import CourseApi from '@/api/waiter/Course'

export default {
  components: {
    AppCardActions,
    MultipleSelect,
  },
  directives: {
    sortableDataTable,
  },
  props: {
    vendibles: {
      type: Array,
      required: true,
      default: () => [],
    },
    vendibleTags: {
      type: Array,
      required: false,
      default: () => [],
    },
    noCursorPointer: {
      type: Boolean,
      default: false,
    },
    courseCollapsed: {
      type: Boolean,
      default: false,
    },
    nominationCollapsed: {
      type: Boolean,
      default: false,
    },
    itemCollapsed: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const selectedVendibleTagIds = ref([])

    const isSelected = targetVendibleTags => {
      const targetIds = map(targetVendibleTags || [], 'id')

      const intersectionIds = intersectionBy(selectedVendibleTagIds.value, targetIds, Number)

      return intersectionIds.length >= selectedVendibleTagIds.value.length
    }

    const vendiblesByType = computed(() => {
      return chain(props.vendibles)
        .filter(vendible => {
          if (selectedVendibleTagIds.value.length < 1) return true

          return isSelected(vendible.attributes.tags.data)
        })
        .orderBy(['attributes.position', 'attributes.name'], ['asc', 'asc'])
        .groupBy('attributes.vendibleType')
        .value()
    })

    const hasActionSlot = computed(() => {
      return context.slots.action
    })

    const updatePositions = async (event, type) => {
      const api = {
        Course: CourseApi,
        Nomination: NominationApi,
        Item: ItemApi,
      }[type]
      if (!api) return

      const func = api.updatePositions
      if (!func) return

      const vendibles = vendiblesByType.value[type]
      const movedVendible = vendibles.splice(event.oldIndex, 1)[0]
      vendibles.splice(event.newIndex, 0, movedVendible)
      const idAndPositions = map(vendibles, (o, idx) => {
        return { id: o.id, position: idx }
      })

      const res = await func(idAndPositions)
    }

    return {
      // data
      selectedVendibleTagIds,
      headers: [
        { text: '名前', value: 'attributes.name' },
        { text: '値段', value: 'attributes.sellingPrice' },
        { text: 'タグ', value: 'attributes.tags.data' },
        { text: '', value: 'actions' },
      ],

      // computed
      vendiblesByType,
      hasActionSlot,

      // methods
      updatePositions,

      icons: {
        mdiPlus,
        mdiFilterVariant,
      },
    }
  },
}
</script>
